/**
 * Takes in an array of classes and combines them use with tailwind (conditional css logic usage)
 * @param classes Array of strings of tailwind classes or regular css class names
 * @returns string of combined class text
 */
export function classNames(...classes): string {
    return classes.filter(Boolean).join(" ");
}

/**
 * Generates a random hex color string from a string
 *
 * This algorithm is deterministic, pseudorandom (I.E. input same string multiple times, get same output)
 * @param str Any basic string (the longer each of your strings in your group the more random)
 * @returns string hex value, including the '#'
 */
export function stringToColor(str: string): string {
    let hash = 0;
    let i = 1;
    if (str) {
        for (i = 0; i < str.length; i += 1) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
}
export function pickTextColorBasedOnBgColor(bgColor: string, lightColor = "#ffffff", darkColor = "#000000") {
    const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
}
