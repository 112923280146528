import { isAfter, isBefore, isSameDay, isThisWeek, isToday, isYesterday } from "date-fns";
import moment from "moment";

/**
 * Finds if a given date is outside a given range of time (inclusive),
 * if end is null then the check will be a range of 1 day
 * @param date A date to check with
 * @param start start of a given date range
 * @param end end of a given date range
 */
export function dateOutsideRange(date: Date, start: Date, end: Date | null = null): boolean {
    // NOTE: This is really dumb that I need to set time parts to zero
    // date-fns purports to compare the dates and not the dates and times,
    // but this is clearly not the case
    const date_copy = new Date(date);
    date_copy.setHours(0, 0, 0, 0);
    const start_copy = new Date(start);
    start_copy.setHours(0, 0, 0, 0);

    if (end) {
        const end_copy = new Date(end);
        end_copy.setHours(0, 0, 0, 0);

        return isBefore(date_copy, start_copy) || isAfter(date_copy, end_copy);
    }

    return !isSameDay(date_copy, start_copy);
}

/**
 * Finds if a given date is within a given range of time (inclusive),
 * if end is null then the check will be a range of 1 day
 * @param date A date to check with
 * @param start start of a given date range
 * @param end end of a given date range
 */
export function dateInsideRange(date: Date, start: Date, end: Date | null = null): boolean {
    return !this.dateOutsideRange(date, start, end);
}

export function notificationsDateStringFormatter(date) {
    date = new Date(date);
    if (isToday(date)) {
        return null;
    } else if (isYesterday(date)) {
        return "Yesterday ";
    } else if (isThisWeek(date)) {
        return moment(date).format("dddd, ").toString();
    } else {
        return moment(date).format("MMM, Do | ").toString();
    }
}

export function calculateDatePercentage(dateStart, dateEnd, dateValue) {
    return ((dateValue - dateStart) / (dateEnd - dateStart)) * 100;
}

/**
 * Given a string like "1w232days 3months", convert it to a number of days (e.g. 1w232days 3months => (1 * 7) + 232 + (3 * 30) = 329)
 * @param input a string of numbers and letters/words that represent a duration in days
 * @returns the total number of days that this string represents
 */
function extractDays(input: string): number {
    let totalDays = 0;

    const regexp = /\d+\D+/g;
    const items = input.match(regexp);

    if (!items) {
        return totalDays;
    }

    for (const item of items) {
        const cleanItem = item.trim();

        const matchedValue = cleanItem.match(/\d+/);
        const matchedUnit = cleanItem.match(/[a-zA-Z]+/);

        if (matchedValue && matchedUnit) {
            const unit = matchedUnit[0];

            if (["day", "days", "day"].includes(unit)) {
                totalDays += parseInt(matchedValue[0]);
            } else if (["week", "weeks", "wk", "wks", "w"].includes(unit)) {
                totalDays += parseInt(matchedValue[0]) * 7;
            } else if (["month", "months", "mon", "mons", "mo", "mos", "m"].includes(unit)) {
                totalDays += parseInt(matchedValue[0]) * 30;
            } else if (["year", "years", "yr", "yrs", "y"].includes(unit)) {
                totalDays += 365;
            }
        }
    }

    return totalDays;
}
